import React from 'react';
import { string } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  WhatsappIcon,
} from 'react-share';

const propTypes = {
  uri: string.isRequired,
};

const query = graphql`
  query ShareSettingsQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const Share = ({ uri }) => {
  const { site } = useStaticQuery(query);
  const url = `${site.siteMetadata.siteUrl}${uri}`;

  return (
    <>
      <FacebookShareButton url={url}>
        <FacebookIcon
          size={32}
          borderRadius={8}
          bgStyle={{ fill: 'white' }}
          iconFillColor="#3b5998"
        />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon
          size={32}
          borderRadius={8}
          bgStyle={{ fill: 'white' }}
          iconFillColor="#00aced"
        />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon
          size={32}
          borderRadius={8}
          bgStyle={{ fill: 'white' }}
          iconFillColor="#007fb1"
        />
      </LinkedinShareButton>
      <EmailShareButton url={url}>
        <EmailIcon
          size={32}
          borderRadius={8}
          bgStyle={{ fill: 'white' }}
          iconFillColor="#7f7f7f"
        />
      </EmailShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon
          size={32}
          borderRadius={8}
          bgStyle={{ fill: 'white' }}
          iconFillColor="#25D366"
        />
      </WhatsappShareButton>
    </>
  );
};

Share.propTypes = propTypes;

export default Share;
