/* eslint-disable react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import Card from '../components/Card/Card';
import Post from '../components/Blog/Post';
import PostCategories from '../components/Blog/PostCategories';
import Share from '../components/Blog/Share';
import DeveloperBar from '../components/DeveloperBar/DeveloperBar';

export const pageQuery = graphql`
  query BlogPostByIdQuery(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $categoryIds: [String]!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      databaseId
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1110)
            }
          }
        }
      }

      author {
        node {
          uri
          name
          description
          avatar {
            url
          }
        }
      }

      categories {
        nodes {
          name
          uri
        }
      }

      seo {
        title
        metaDesc
        canonical
        schema {
          raw
        }
      }
    }
    # selecting related post from post categories
    relatedPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { in: $categoryIds } } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 2
    ) {
      nodes {
        uri
        title
        date(formatString: "MMMM DD, YYYY")
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        author {
          node {
            uri
            name
          }
        }
      }
    }
  }
`;

const BlogPostPage = ({ data: { post, relatedPosts }, path }) => {
  // const featuredImage = {
  //   image: getImage(post.featuredImage?.node?.localFile),
  //   alt: post.featuredImage?.node?.altText || ``,
  // };
  const author = post.author.node;

  return (
    <>
      {!!post.seo && (
        <Seo
          title={post.seo.title}
          description={post.seo.metaDesc}
          canonical={post.seo.canonical}
          schema={post.seo.schema.raw}
        />
      )}

      <DeveloperBar postId={post.databaseId} />

      <BrandGrid />

      <Container>
        <div itemType="http://schema.org/Article" itemScope>
          <Row className="justify-content-md-center">
            <Col lg={8}>
              <header className="ui-header">
                <h1 className="h1--center text-center" itemProp="headline">
                  {parse(post.title)}
                </h1>
              </header>
            </Col>
          </Row>

          {!!post.content && (
            <>
              <Row className="justify-content-md-center mb-4">
                <Col lg={10}>
                  <Row className="justify-content-center justify-content-md-between align-items-center">
                    <Col
                      md={6}
                      className="d-flex justify-content-center justify-content-md-start"
                    >
                      <div className="ui-avatar ms-2">
                        {!!author.avatar && (
                          <img
                            src={author.avatar.url}
                            alt={author.name}
                            width={60}
                            height={60}
                            className="ui-avatar--image"
                          />
                        )}
                        <div className="ui-avatar--info">
                          <p className="mb-0">
                            <Link
                              to={author.uri}
                              className="text-body font-size-sm"
                            >
                              {author.name}
                            </Link>
                          </p>
                          <p className="font-size-xs text-muted mb-0">
                            Posted on {post.date}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="text-center text-md-end mt-4 mt-md-0"
                    >
                      <Share uri={path} />
                    </Col>
                  </Row>

                  {/* if we have a featured image for this post let's display it */}
                  {/* {featuredImage?.image && (
                    <GatsbyImage
                      image={featuredImage.image}
                      alt={featuredImage.alt}
                      className="my-5"
                    />
                  )} */}
                </Col>
              </Row>

              <Row className="justify-content-md-center">
                <Col lg={8}>
                  {/* Content */}
                  <div className="ui-wordpress-content" itemProp="articleBody">
                    {parse(post.content)}
                  </div>

                  <Row className="align-items-center justify-content-between mt-6">
                    <Col
                      xs={12}
                      className="d-flex justify-content-center justify-content-md-start mb-5 mb-md-0"
                    >
                      {/* Categories */}
                      {post.categories.nodes.length > 0 && (
                        <div>
                          <PostCategories categories={post.categories.nodes} />
                        </div>
                      )}
                    </Col>
                    {/* <Col md={6} className="text-md-end text-center">
                      <Share uri={path} />
                    </Col> */}
                  </Row>

                  <hr className="my-5" />

                  {/* Author box */}
                  <Card>
                    <div className="ui-avatar ms-2">
                      {!!author.avatar && (
                        <img
                          src={author.avatar.url}
                          alt={author.name}
                          width={60}
                          height={60}
                          className="ui-avatar--image"
                        />
                      )}
                      <div className="ui-avatar--info">
                        <p className="mb-0">
                          <Link className="h5 d-block" to={author.uri}>
                            {author.name}
                          </Link>
                        </p>
                        <p className="font-size-sm mb-0">
                          {author.description}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>

        {/* Related posts */}
        {relatedPosts.nodes.length > 0 && (
          <Row className="justify-content-md-center mt-6">
            <Col lg={10}>
              <h3 className="mb-3 pb-3 border-bottom">Related Posts</h3>
              {relatedPosts.nodes.map((relatedPost) => {
                const relatedFeaturedImage = {
                  image: getImage(relatedPost.featuredImage?.node?.localFile),
                  alt: relatedPost.featuredImage?.node?.altText || ``,
                };

                return (
                  <React.Fragment key={relatedPost.uri}>
                    <Post
                      uri={relatedPost.uri}
                      title={relatedPost.title}
                      featuredImage={relatedFeaturedImage}
                      date={relatedPost.date}
                      author={relatedPost.author.node}
                      excerpt={relatedPost.excerpt}
                    />
                    <hr className="no-border my-5" />
                  </React.Fragment>
                );
              })}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default BlogPostPage;
